@use '@angular/material' as mat;
@import "mixins";
@import "reset";
@import "variables";
@import "global";

@include mat.core();
$mat-myapp-accent: (
  50: #fff6e7,
  100: #ffe8c4,
  200: #ffd99c,
  300: #ffca74,
  400: #ffbe57,
  500: #ffb339,
  600: #ffac33,
  700: #ffa32c,
  800: #ff9a24,
  900: #ff8b17,
  A100: #ffffff,
  A200: #fffefc,
  A400: #ffe2c9,
  A700: #ffd5b0,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$mat-myapp-primary: (
  50: #fff6e7,
  100: #ffe8c4,
  200: #ffd99c,
  300: #ffca74,
  400: #ffbe57,
  500: #ffb339,
  600: #ffac33,
  700: #ffa32c,
  800: #ff9a24,
  900: #ff8b17,
  A100: #ffffff,
  A200: #fffefc,
  A400: #ffe2c9,
  A700: #ffd5b0,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$mat-myapp-warn: (
  50: #ffffff,
  100: #fee9e8,
  200: #fbb8b3,
  300: #f7786f,
  400: #f65d52,
  500: #f44235,
  600: #f22718,
  700: #e01b0c,
  800: #c3170b,
  900: #a61409,
  A100: #ffffff,
  A200: #ffc7c3,
  A400: #ff685d,
  A700: #fa5548,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$primary: mat.define-palette($mat-myapp-primary);
$accent: mat.define-palette($mat-myapp-accent);
$warn: mat.define-palette($mat-myapp-warn);
$theme: mat.define-dark-theme($primary, $accent, $warn);
@include mat.all-component-themes($theme);

$custom-typography: mat.define-typography-config(
  $font-family: "'Source Sans Pro', sans-serif"
);

@include mat.all-component-typographies($custom-typography);

.mat-input-element {
  color: #ffffff !important;
}

.page-form {
  .custom-dropzone {
    height: 250px;
    background: #424242;
    color: #fff;
    border: 2px dashed rgba(255, 255, 255, 0.7);
    border-radius: 0px;
    font-size: 20px;
    width: 100%;
    margin-bottom: 16px;
    transition: all 200ms;
  }
  
  .custom-dropzone.ngx-dz-hovered {
    border: 2px solid $color-primary;
  }
}
